import React from 'react';
import { Card } from 'antd';
import { isMobile } from "react-device-detect";

const style = {
    card: {
        margin: '20px',
        textAlign: 'left'
    },
    page: {
        paddingLeft: isMobile ? '10px' : '100px', 
        paddingRight: isMobile ? '10px' : '100px', 
        paddingTop: '50px',
        textAlign: 'center',
    },
}


const Covid = () =>  {
    return(
        <div style={style.page}>
            <h1 style={{ paddingBottom: '25px' }}>COVID UPDATES</h1>
            <Card style={style.card}>
                <ul>
                    <li>
                        If you are able to get vaccinated, we urge you to please do so!
                    </li>
                    <li>
                        Please bring a mask with you. Though they are not required for any of our events, we do have several events in shared spaces and they may request 
                        that you wear a mask while waking to the bathroom or using common space that is not specifically rented out for our group.
                    </li>
                    <li>
                        We recommend that you bring proof of vaccination. We won't require it for our events, but many places in Seattle are starting to.
                    </li>
                    <li>
                        Whether you are vaccinated or not, if you are feeling sick, please use your best judgement and stay home if necessary. We will miss you terribly but
                        want to make sure we keep everyone as safe as possible!
                    </li>
                </ul>
                <div style={{textAlign: 'center'}}>
                    <a href="https://kingcounty.gov/depts/health/covid-19.aspx" target="_blank">King Country COVID-19 Information and Resources</a>
                </div>
            </Card>
            
        </div>
    )
}

export default Covid;